a { cursor: pointer; }

.app-container {
    min-height: 350px;
}

.company-btn {
    height: 100px;
    width: 160px;
    margin: 5px;
}

.module-btn {
    height: 60px;
    width: 140px;
    margin: 5px;
}

.margin-btn {
    margin: 3px;
}


.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  color: #fff;
  background-color: #0c1a1e;
  text-align: left;
  padding: 10px;
  justify-content: center;
  font-size: 11px;  
}

.footer a:link {
    text-decoration: none;
}

.table_border {
  font-size: 11px;  
}

.error {
    color: red;
}

